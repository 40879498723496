import React from "react";

// Customizable Area Start
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Alert from '@material-ui/lab/Alert';
// Customizable Area End

import SavesearchController, {
  Props,
  configJSON,
} from "./SavesearchController";

export default class Savesearch extends SavesearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isLoading, showSearch, searchQuery, users, searches, loadSearchesOnly, showAlert } = this.state;

    if (isLoading) {
      return (
        <div style={webStyle.loadingContainer}>
          <CircularProgress />
        </div>
      );
    }

    return (
      <Container maxWidth={"sm"} style={loadSearchesOnly ? webStyle.mainWrapperLoading : webStyle.mainWrapper as any}>
        {showSearch ? (
          <>
            <Button
              data-test-id="navigateBackButton"
              startIcon={<ArrowBackIcon />}
              onClick={this.onNavigateBack}
            >
              {configJSON.goBack}
            </Button>
            <div style={webStyle.searchContainer}>
              <TextField
                variant="outlined"
                size="small"
                placeholder={configJSON.searchPlaceholder}
                fullWidth={true}
                style={webStyle.inputStyle}
                value={searchQuery}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      onClick={this.saveSearch}
                      disabled={!this.isSavingEnabled()}
                    >
                      <AddIcon />
                    </IconButton>
                  )
                }}
                onChange={(event) =>
                  this.onChangeSearchQuery(event.target.value)
                }
                onKeyUp={(event) => {
                  if (this.isSavingEnabled() && event.key === "Enter") {
                    this.saveSearch();
                  }
                }}
              />
            </div>
            {
              showAlert ? (
                <Alert severity="error" style={webStyle.alert} data-test-id="lengthLimitAlert">
                  {configJSON.validationMessage}
                </Alert>
              ) : null
            }
            {
              loadSearchesOnly ? (
                <div style={webStyle.spinner}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <ul style={webStyle.list} data-test-id="searches">
                    {searches.length > 0 &&
                      searches.sort((search1, search2) => {
                        const date1 = new Date(search1.created_at).getTime();
                        const date2 = new Date(search2.created_at).getTime();

                        return date2 - date1;
                      })
                      .filter((search) => {
                        return search.name.toLowerCase().startsWith(searchQuery.trim().toLowerCase());
                      }).map((search) => (
                        <li
                          key={search.id}
                          style={{ ...webStyle.listItem, ...webStyle.searchItem }}
                        >
                          <Typography>{search.name}</Typography>
                          <IconButton
                            data-test-id="deleteSearchButton"
                            style={webStyle.clearButton}
                            onClick={() => this.deleteSearch(search.name)}
                          >
                            <ClearIcon />
                          </IconButton>
                        </li>
                      ))}
                  </ul>
                </>
              )
            }
          </>
        ) : (
          <>
            <Typography variant="h5" gutterBottom>
              {configJSON.usersScreenHeading}
            </Typography>
            <ul style={webStyle.list} data-test-id="userList">
              {users.length > 0 &&
                users.map((user) => (
                  <li
                    data-test-id="userSelectButton"
                    key={user.id}
                    style={webStyle.listItem}
                    onClick={() => this.onSelectUser(user)}
                  >
                    <Typography variant="h6">{user.full_name}</Typography>
                    <Typography variant="subtitle2">{user.email}</Typography>
                  </li>
                ))}
            </ul>
          </>
        )}
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    marginTop: "16px",
    overflowX: "hidden"
  },
  mainWrapperLoading: {
    marginTop: "16px",
    overflow: "hidden"
  },
  inputStyle: {
    margin: "16px 0",
  },
  alert: {
    marginBottom: "16px"
  },
  list: {
    listStyle: "none",
    padding: "0",
    margin: "0",
  },
  listItem: {
    padding: "8px",
    marginBottom: "8px",
    border: "1px solid #CCC",
    cursor: "pointer",
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  searchItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  clearButton: {
    padding: "0",
  },
  loadingContainer: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
};
// Customizable Area End
